/** @jsx jsx */
import React from "react"
import { jsx, Box } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductsList from "../components/products-list"
import HeaderCard from "../components/header-card"
import TagsConstructor from "../components/tags"
import jamstackStyle from "../components/jamstack.styles"
import PromoCard from "../components/promo-card"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    featuredProduct: allStrapiProducts(
      filter: { productType: { elemMatch: { price: { gt: 0 } } } }
      limit: 1
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          id
          title
          slug
          prefix
          meta_description
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    products: allStrapiProducts(
      limit: 1000
      sort: { order: DESC, fields: published_at}
    ) {
      totalCount
      edges {
        node {
          id
          created_at
          updated_at
          slug
          prefix
          title
          meta_description
          productType {
            githubURL
            snipcartGUID
            price
          }
          styling {
            title
          }
          liveDemo
          headlessCMS {
            title
          }
          ssg {
            title
          }
          author {
            title
            slug
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [416, 832, 1248, 1664]
              )
            }
          }
        }
      }
    }
  }
`

class JamstackPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showPosts: 12 }

    this.handleLoadMore = this.handleLoadMore.bind(this)
  }

  handleLoadMore() {
    this.setState(state => ({
      showPosts: state.showPosts + 9,
    }))
  }

  render() {
    const {
      products,
      productCount = products.totalCount,
      featuredProduct,
    } = this.props.data
    return (
      <Layout>
        <SEO
          title="Marketplace for Jamstack Templates, Themes and Starters • Stackrole"
          description="A curated collection of best Jamstack templates, themes and starters from open-source communities of amazing Static Site Generators and Headless CMS"
        />
        <div sx={jamstackStyle.navBg}>
          <div sx={jamstackStyle.navContainer}>
            <TagsConstructor {...jamstackStyle.navDropdown} />
          </div>
        </div>
        <div sx={jamstackStyle.container}>
          <Box sx={jamstackStyle.containerBox}>
            <section sx={jamstackStyle.containerSection}>
              <HeaderCard>
                <h1>Marketplace for Jamstack Templates, Themes and Starters</h1>
                <p>
                  A curated collection {productCount}+ of best templates, themes
                  and starters for launching blazing fast websites with
                  Jamstack.
                </p>
                <p>
                  Each Jamstack template is handpicked from open-source
                  communities of amazing Static Site Generators(SSG) and
                  Headless CMS, with at most care and considering for UI/UX,
                  features, flexibility to customize and make sure the template
                  builds properly to deploy quickly{" "}
                  <span role="img" aria-label="speed">
                    🚀
                  </span>
                </p>
              </HeaderCard>
              <PromoCard
                data={featuredProduct}
                sx={{
                  borderRadius: "16px",
                  mb: 4,
                  py: [3, 4, 4, 5],
                }}
              />
              <ProductsList
                data={products}
                isHome={false}
                showPosts={this.state.showPosts}
              />
              {this.state.showPosts < productCount && (
                <button
                  onClick={this.handleLoadMore}
                  className="button"
                  sx={{ variant: "variants.paginationButton" }}
                >
                  Load more
                </button>
              )}
            </section>
          </Box>
        </div>
      </Layout>
    )
  }
}

export default JamstackPage
