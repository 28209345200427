/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { RiArrowRightLine } from "react-icons/ri"
import LinkButton from "./link-button"

export default function PromoCard(props) {
  const products = props.data
  const promoCard = products.edges.map((edge, index) => {
    const product = edge.node
    const image = product.featuredImage
      ? product.featuredImage.childImageSharp.gatsbyImageData
      : ""
    const productURL = product.prefix + product.slug + "/"
    return (
      <div
        className={props.className}
        key={"promocard" + index}
        sx={promoStyles.card}
      >
        <div sx={promoStyles.container} key={"promo" + product.id}>
          {image && (
            <Link to={productURL} sx={promoStyles.imageOrder}>
              <GatsbyImage image={image} alt={product.title + " - cover"} />
            </Link>
          )}
          <div sx={promoStyles.infoOrder}>
            <span sx={promoStyles.label}>FEATURED PRODUCT</span>
            <Link to={productURL} sx={promoStyles.title}>
              <h3>{product.title}</h3>
            </Link>
            <p sx={promoStyles.desc}>{product.meta_description}</p>
            <LinkButton
              to={productURL}
              className="button"
              sx={promoStyles.button}
            >
              Learn more
              <span className="icon -right">
                <RiArrowRightLine />
              </span>
            </LinkButton>
          </div>
        </div>
      </div>
    )
  })

  return <div>{promoCard}</div>
}

const promoStyles = {
  label: {
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: 1,
    display: "block",
    mt: [0, 0, 0, 3, 5],
    mb: [4, 4, 4, 3, 4],
    color: "#6fa401",
  },
  // imageOrder: {
  //   order: [2, 2, 2, 1],
  // },
  // infoOrder: {
  //   order: [1, 1, 1, 2],
  // },
  desc: {
    fontSize: [3],
    mb: [4, 4, 5],
  },
  title: {
    display: "inline-block",
    color: "#0b1207",
    fontSize: [4, 4, 4, 4, 5],
    h3: {
      fontWeight: "extraBold",
      mb: 0,
    },
  },
  price: {
    fontSize: [3, 4],
    small: {
      fontWeight: 300,
    },
  },
  card: {
    bg: "#fff",
    pt: [5],
    pb: [5],
  },
  container: {
    variant: "variants.featHome",
    px: [3, 4, 4, 5],
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr", "4fr 2fr"],
    gridGap: [3, 4, 5],
    alignItems: "initial",
    img: {
      borderRadius: "16px",
      overflow: "hidden",
    },
  },
  button: {
    bg: "#0b1207",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      bg: "#b5cd83",
      color: "#0b1207",
    },
  },
}
